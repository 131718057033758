<template>
  <div class="tab-pane active" id="pipeline-stats">
    <div class="card-body">
      <div class="p-2 mb-5">
        <chart
          ref="deals"
          title="Negócios por prioridade"
          :data="deals"
          :loading="loadingDeals" />
      </div>
      <div class="p-2">
        <chart
          ref="deals"
          title="Tarefas por tipo"
          :data="tasks"
          :loading="loadingTasks" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import LottieAnimMsgGreen from '@/components/rebranding/animLottie/MsgGreen.vue';
// import LottieAnimMsgPurple from '@/components/rebranding/animLottie/MsgPurple.vue';
// import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
// import Fail from '@/components/rebranding/animLottie/Fail.vue';
// import Tarifada from '@/components/rebranding/animLottie/Tarifada.vue';

import Chart from '@/components/rebranding/Chart.vue';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'PipelineStats',
  components: {
    // LottieAnimMsgGreen,
    // LottieAnimMsgPurple,
    // LoadingAnim,
    // Fail,
    // Tarifada,
    Chart,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      inbound: 0,
      outbound: 0,
      failures: 0,
      charged: 0,

      deals: [],
      loadingDeals: true,

      tasks: [],
      loadingTasks: true,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetchDealsMessages([]);
      this.fetchTasksMessages([]);
    },
    fetchDealsMessages(filter) {
      this.loadingDeals = true;

      webApi.post('/pipelines/reports/deals', {
        start_date: this.startDate,
        end_date: this.endDate,
        group_by: 'priority',
        filter,
      }).then((response) => {
        console.log('fetchDealsMessages', response.data);
        this.deals = response.data;
      }).catch((error) => {
        this.$toast.show({
          title: `Erro ${error.code}`,
          content: error.message,
          type: 'danger',
        });
      }).finally(() => {
        this.loadingDeals = false;
      });
    },
    fetchTasksMessages(filter) {
      this.loadingTasks = true;

      webApi.post('/pipelines/reports/tasks', {
        start_date: this.startDate,
        end_date: this.endDate,
        group_by: 'type',
        filter,
      }).then((response) => {
        console.log('fetchTasksMessages', response.data);
        this.tasks = response.data;
      }).catch((error) => {
        this.$toast.show({
          title: `Erro ${error.code}`,
          content: error.message,
          type: 'danger',
        });
      }).finally(() => {
        this.loadingTasks = false;
      });
    },
    exportData(format) {
      this.$refs.carrierMessages.exportData(format);
      this.$refs.statusMessages.exportData(format);
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-column-right {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
}
.card-messages {
  /* margin-left: auto; */
  max-height: 230px;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--clr-light-2);
      font-weight: bold;
    }
  }
}
#infos-bottom,
#infos-top {
  position: relative;
  z-index: 1;
  min-width: 260px;
}
.card-messages.inner-card {
  /* position: absolute;
  right: 0; */
  /* opacity: 0; */
  z-index: 1;
}
.card-messages.inner-card.active {
  /* position: absolute;
  right: 325px; */
  z-index: 0;
  /* opacity: 1; */
}
/* .card-messages.inner-card.active:last-of-type {
  right: 470px;
} */

.darkmode .card {
  background-color: var(--background-3);
}
</style>
